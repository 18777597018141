<template>
  <b-modal id="modal-inventory-details" size="xl" hide-footer>
    <template v-slot:modal-header="{ close }">
      <h5 class="modal-title" id="exampleModalLabel">
        <a
          @click="$bvModal.hide('modal-inventory-details')"
          class="prevButton backArrow mr-2"
          data-dismiss="modal"
        >
          <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 512 512"
            style="enable-background:new 0 0 512 512;"
            xml:space="preserve"
          >
            <g>
              <g>
                <path
                  d="M501.333,245.333H36.417l141.792-141.792c4.167-4.167,4.167-10.917,0-15.083c-4.167-4.167-10.917-4.167-15.083,0l-160,160
          c-4.167,4.167-4.167,10.917,0,15.083l160,160c2.083,2.083,4.813,3.125,7.542,3.125c2.729,0,5.458-1.042,7.542-3.125
          c4.167-4.167,4.167-10.917,0-15.083L36.417,266.667h464.917c5.896,0,10.667-4.771,10.667-10.667S507.229,245.333,501.333,245.333z
          "
                />
              </g>
            </g>
          </svg>
        </a> Inventory
      </h5>
      <button @click="close()" type="button" class="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </template>

    <div class="outer-wrapper">
      <div class="row justify-content-center ml-2 mr-2">
        <div class="col-md-8 pr-0">
          <div class="flatDetailsHolder">
            <b-tabs content-class pills card>
              <b-tab active>
                <template v-slot:title>Flat Details</template>
              
                <div class="row">
                  <div class="col-6 col-md-4 grid">
                    <span class="lead-title d-block">Wing Name</span>
                    <span class="lead-info d-block">{{selectedWing.name}}</span>
                  </div>
                  <div class="col-6 col-md-4 grid">
                    <span class="lead-title d-block">Price</span>
                    <span class="lead-info d-block">76 Lac</span>
                  </div>
                  <div class="col-6 col-md-4 grid">
                    <span class="lead-title d-block">Built up Area</span>
                    <span class="lead-info d-block">1140 Sqft</span>
                  </div>
                  <div class="col-6 col-md-4 grid">
                    <span class="lead-title d-block">Parking</span>
                    <span class="lead-info d-block">1(Coverd)</span>
                  </div>
                  <div class="col-6 col-md-4 grid">
                    <span class="lead-title d-block">Facing</span>
                    <span class="lead-info d-block">West</span>
                  </div>
                  <div class="col-6 col-md-4 grid">
                    <span class="lead-title d-block">Floor</span>
                    <span class="lead-info d-block">Higher</span>
                  </div>
                  <div class="col-6 col-md-4 grid">
                    <span class="lead-title d-block">Age of Property</span>
                    <span class="lead-info d-block">3 years 11 months</span>
                  </div>
                  <div class="col-6 col-md-4 grid">
                    <span class="lead-title d-block">Loan on Property</span>
                    <span class="lead-info d-block">No</span>
                  </div>
                  <div class="col-6 col-md-4 grid">
                    <span class="lead-title d-block">Negotiable</span>
                    <span class="lead-info d-block">Yes</span>
                  </div>
                </div>
                <h3 class="mb-2" style="font-weight: 600; font-size: 15px;">Amenities</h3>
                <div class="row amenities">
                  <div class="col-6 col-xs-6 col-md-3 p-1">
                    <div class="grid-amenities">
                      <h3>Social & Entertainment</h3>
                      <ul>
                        <li>Labyrinth Seating</li>
                        <li>Green Amphitheatre#</li>
                        <li>Party Lawn </li>
                        <li>Social Islands# </li>
                        <li>Crèche* </li>
                        <li>Flag Hoisting Zone</li>
                        <li>Chit-chat Corner#</li>
                        <li>Senior Citizens' Plaza#</li>
                        <li>Teenagers' Garden#</li>
                        <li>Amphitheatre#</li>
                        <li>Parents' Zone#</li>
                        <li>Festival Lawns#</li>
                        <li>Multipurpose Hall#</li>
                        <li>Open-air Cinema#</li>
                        <li>Proposed Shopping Galleria</li>
                      </ul>
                      <h3>Health & Fitness</h3>
                      <ul>
                        <li>Gym</li>
                        <li>Health Café</li>
                        <li>Jogging/Cycling path </li>
                        <li>Open-Air Gym </li>
                        <li>Kabaddi Play Area</li>
                      </ul>
                    </div>
                  </div>
                  <div class="col-6 col-xs-6 col-md-3 p-1">
                    <div class="grid-amenities">
                      <h3>Gaming Arena</h3>
                      <ul>
                        <li> Kids' Pool</li>
                        <li>Indoor Gaming Area</li>
                        <li>Childrens' Play Area </li>
                        <li>Twisters </li>
                        <li>Life-size Ludo </li>
                        <li>Life-Size Hopscotch</li>
                        <li>Adventure Play Zone</li>
                        <li>Rock Climbing</li>
                        <li>Futsal Court</li>
                        <li>Top Play Area</li>
                        <li>Marble Play Area</li>
                        <li>Box Cricket</li>
                        <li>Skating Rink</li>
                        <li>Lawn (Nostalgic Game play Area)</li>
                        <li>P4 Mini Clubs</li>
                      </ul>
                    </div>
                  </div>
                  <div class="col-6 col-xs-6 col-md-3 p-1">
                    <div class="grid-amenities">
                      <h3>Recreation</h3>
                      <ul>
                        <li>Hammock Garden</li>
                        <li>Lawn Mound</li>
                        <li>Viewing Pavilion </li>
                        <li>Reading Nook </li>
                        <li>Provision for BBQ Lawn</li>
                        <li>Swimming Pool</li>
                        <li>Multipurpose Court#(Basketball, Volleyball)</li>
                        <li>Tennis Court</li>
                        <li>Open Cricket Pavilion</li>
                        <li>Pet Park#</li>
                        <li>Cycle Parking</li>
                        <li>Bonfire</li>
                      </ul>
                    </div>
                  </div>
                  <div class="col-6 col-xs-6 col-md-3 p-1">
                    <div class="grid-amenities">
                      <h3>Wellness</h3>
                      <ul>
                        <li>Fruit Orchard</li>
                        <li>Gazebo#</li>
                        <li>Camp Site </li>
                        <li>Tamarind Court </li>
                        <li>Floral Tunnel</li>
                        <li>Aroma Garden</li>
                        <li>Flower Garden</li>
                        <li>Herbal Garden</li>
                        <li>Teenagers' Garden#</li>
                        <li>AUrban Garden</li>
                        <li>Acupressure Pathway</li>
                      </ul>
                     
                    </div>
                  </div>
             

               
                </div>
              </b-tab>
              <b-tab>
                <template v-slot:title>Payment details</template>
                <h3 class="mb-2" style="font-weight: 600; font-size: 15px; margin-left: 10px;">Price Breakup</h3>
                <div class="row justify-content-center">
                  <div class="col-md-12">
  <table class="table table-borderless">
                  <thead>
                    <tr>
                      <th>Description</th>
                      <th>Price</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>BSP (Basic Selling Price)</td>
                      <td>
                        <i class="fa fa-inr"></i> &nbsp; 64,20,060
                      </td>
                    </tr>
                    <tr>
                      <td>Car Park</td>
                      <td>
                        <i class="fa fa-inr"></i> &nbsp; 20,060
                      </td>
                    </tr>
                    <tr>
                      <td>Floor Rise</td>
                      <td>
                        <i class="fa fa-inr"></i> &nbsp; 64,20,060
                      </td>
                    </tr>
                    <tr>
                      <td>Electric supply, Water and Connection Charges</td>
                      <td>
                        <i class="fa fa-inr"></i> &nbsp; 64,20,060
                      </td>
                    </tr>
                    <tr></tr>
                    <tr>
                      <td>5 year Maintenance Charges</td>
                      <td>
                        <i class="fa fa-inr"></i> &nbsp; 64,20,060
                      </td>
                    </tr>
                    <tr>
                      <td>VAT</td>
                      <td>
                        <i class="fa fa-inr"></i> &nbsp; 64,20,060
                      </td>
                    </tr>
                    <tr>
                      <td>Stamp Duty &amp; Registration Charges</td>
                      <td>
                        <i class="fa fa-inr"></i> &nbsp; 64,20,060
                      </td>
                    </tr>
                    <tr>
                      <td>Service Tax on BSP</td>
                      <td>
                        <i class="fa fa-inr"></i> &nbsp; 64,20,060
                      </td>
                    </tr>
                    <tr>
                      <td>Service Tax on Maintenance</td>
                      <td>
                        <i class="fa fa-inr"></i> &nbsp; 64,20,060
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Total</strong>
                      </td>
                      <td>
                        <i class="fa fa-inr"></i> &nbsp;
                        <strong>64,20,060</strong>
                      </td>
                    </tr>
                  </tbody>
                </table>
                  </div>
                </div>
              
              </b-tab>
              <b-tab>
                <template v-slot:title>Floor Plan</template>
                <div class="smallTab">
                  <b-tabs pills card vertical>
                    <b-tab title="2D" active>
                      <div class="row">
                        <div class="col-md-12">
                          <a :href="require('@/assets/images/2d-plan.jpg')" data-fancybox="gallery">
                            <img
                              src="@/assets/images/2d-plan.jpg"
                              class="img-fluid w-100 floorPlanImg"
                            />
                          </a>
                        </div>
                      </div>
                    </b-tab>
                    <b-tab title="3D">
                      <div class="row">
                        <div class="col-md-12">
                          <a
                            :href="require('@/assets/images/3d-image.jpg')"
                            data-fancybox="gallery"
                          >
                            <img
                              src="@/assets/images/3d-image.jpg"
                              class="img-fluid w-100 floorPlanImg"
                            />
                          </a>
                        </div>
                      </div>
                    </b-tab>
                  </b-tabs>
                </div>
              </b-tab>
            </b-tabs>
          </div>
        </div>

        <div class="ct-col col-md-4 pl-0">
          <div class="side-menu prev m-0 payment-summery">
            <div class>
              <h3>Booking Summary Flat: {{selectedFlat.name}}</h3>

              <div class="payment-grid">
                <div class="small mb-2">Your selection</div>
                <div class="list-group">
                  <div class="list-group-item">
                    <span class="title-payment">Typology</span>
                    <div class="divider"></div>
                    <p class="mb-1 d-block">{{selectedTypology.value}}</p>
                  </div>
                  <div class="list-group-item">
                    <span class="title-payment">Floor Range</span>
                    <div class="divider"></div>
                    <p class="mb-1 d-block">{{selectedFloor.value}}</p>
                  </div>

                  <div class="list-group-item">
                    <span class="title-payment">Building</span>
                    <div class="divider"></div>

                    <p class="mb-1 d-block">{{selectedWing.name}}</p>
                  </div>

                  <div class="list-group-item">
                    <div class="form-group">
                      <span class="title-payment">Select Payment Plan</span>
                      <select
                        name
                        id
                        class="form-control custom-select d-block"
                        v-model="selectedPlan"
                      >
                        <option value disabled>Select Plan</option>
                        <option v-for="plan in paymentPlans" :value="plan">{{plan}}</option>
                      </select>
                    </div>
                  </div>
                  <div class="list-group-item">
                    <div class="form-group">
                      <div class="row">
                        <div class="col-md-6">
                          <span
                            class="title-price"
                            v-b-popover.hover.top="selectedPlan ? 'Click to Proceed!' : 'Select Plan to Book!'"
                          >Rs 50,0000</span>
                          <span class="title-payment d-block" style="font-size:12px">Price (All incl)</span>
                        </div>
                        <div class="col-md-6">
                          <button
                            class="btn btn-success success-btn float-right"
                            type="button"
                            :disabled="!selectedPlan"
                            @click="goBook"
                          >Book Now</button>
                        </div>
                      </div>

                      <small class="text-muted d-block mt-4">By clicking on “Book Now” you agree to our Terms & Conditions</small>
                       <button type="button" class="btn btn-outline prevButton mt-4 p-0">Go back</button>
                    </div>
                  </div>
             
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>
<script>
import {
  projectName,
  paymentPlans,
  santizeBookingParams,
  bookingUrl,
} from "@/assets/scripts/utility.js";
import qs from "qs";
export default {
  props: {
    selectedWing: {
      type: Object,
      required: true,
    },
    selectedFlat: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selectedPlan: null,
    };
  },
  methods: {
    goBook() {
      let qp = {
        selectedPlan: this.selectedPlan,
        flatName: this.selectedFlat.name,
        wingName: this.selectedWing.name,
        typologyName: this.selectedTypology.value,
        floorName: this.selectedFloor.value,
        leadNo: localStorage.getItem("crmleadNo"),
        cpId: localStorage.getItem("enrichr-augment-p1-cp-id"),
      };
      let qParams = qs.stringify(santizeBookingParams(qp));
      let finalUrl = `${this.$axios.defaults.baseURL}${bookingUrl}?${qParams}`;
      window.location = finalUrl;
    },
  },
  computed: {
    selectedTypology() {
      return this.$store.getters.selectedTypology;
    },
    selectedFloor() {
      return this.$store.getters.selectedFloor;
    },
    projectName() {
      return projectName;
    },
    paymentPlans() {
      return paymentPlans;
    },
  },
};
</script>
<style scoped>
.outer-wrapper {
    margin-top: 20px;
}
</style>